import { memo, SVGProps } from 'react';

const SvgIcoWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.01145 0.840241C8.91606 0.670762 8.77115 0.528532 8.59266 0.429214C8.41418 0.329896 8.20904 0.277344 7.99985 0.277344C7.79066 0.277344 7.58552 0.329896 7.40704 0.429214C7.22855 0.528532 7.08364 0.670762 6.98826 0.840241L0.736823 12.2215C0.649633 12.379 0.608121 12.5542 0.61623 12.7304C0.62434 12.9067 0.6818 13.0781 0.783156 13.2284C0.884511 13.3787 1.0264 13.5029 1.19534 13.5893C1.36428 13.6756 1.55467 13.7213 1.74842 13.7218H14.2513C14.445 13.7213 14.6354 13.6756 14.8044 13.5893C14.9733 13.5029 15.1152 13.3787 15.2165 13.2284C15.3179 13.0781 15.3754 12.9067 15.3835 12.7304C15.3916 12.5542 15.3501 12.379 15.2629 12.2215L9.01145 0.840241ZM7.99985 4.23698C8.36804 4.23698 8.66652 4.53546 8.66652 4.90365V8.0465C8.66652 8.41469 8.36804 8.71317 7.99985 8.71317C7.63166 8.71317 7.33318 8.41469 7.33318 8.0465V4.90365C7.33318 4.53546 7.63166 4.23698 7.99985 4.23698ZM8.6665 11C8.6665 11.3682 8.36803 11.6667 7.99984 11.6667C7.63165 11.6667 7.33317 11.3682 7.33317 11C7.33317 10.6318 7.63165 10.3333 7.99984 10.3333C8.36803 10.3333 8.6665 10.6318 8.6665 11Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIcoWarning);
export default Memo;
