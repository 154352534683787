import { Box, Stack, Typography } from '@mui/material';
import { motion, Variants } from 'motion/react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { IcoArrow } from 'components/@icons/custom';

interface Props {
  score: number;
  width?: number;
}

const arrowMotion = (x: number, y: number): Variants => {
  const anim = [-5, -10, 0, -10, -5];
  return {
    animate: {
      opacity: [0, 1, 0, 1, 0],
      color: ['#fff', '#3D3D3D', '#fff', '#3D3D3D', '#fff'],
      y: y !== 0 ? anim.map((d) => d * y) : undefined,
      x: x !== 0 ? anim.map((d) => d * -x) : undefined,
      transition: {
        repeat: Infinity,
        duration: 2.4,
      },
    },
  };
};

const Points = ({ score, width = 188 }: Props) => {
  return (
    <Box py={3} position="relative" width={width} textAlign="center">
      <Box
        color="neutral01.300"
        position="absolute"
        top={-8}
        left={0}
        right={0}
      >
        <motion.div animate={'animate'} variants={arrowMotion(0, 1)}>
          <IcoArrow width={24} height={12} />
        </motion.div>
      </Box>
      <Stack
        color="neutral01.300"
        position="absolute"
        justifyContent="center"
        top={0}
        bottom={0}
        right={-6}
      >
        <motion.div animate={'animate'} variants={arrowMotion(1, 0)}>
          <IcoArrow
            width={24}
            height={12}
            style={{ transform: 'rotate(90deg)' }}
          />
        </motion.div>
      </Stack>
      <Box
        position="absolute"
        color="neutral01.300"
        left={0}
        right={0}
        bottom={-8}
      >
        <motion.div animate={'animate'} variants={arrowMotion(0, -1)}>
          <IcoArrow
            width={24}
            height={12}
            style={{ transform: 'rotate(180deg)' }}
          />
        </motion.div>
      </Box>
      <Stack
        color="neutral01.300"
        position="absolute"
        justifyContent="center"
        top={0}
        bottom={0}
        left={-6}
      >
        <motion.div animate={'animate'} variants={arrowMotion(-1, 0)}>
          <IcoArrow
            width={24}
            height={12}
            style={{ transform: 'rotate(-90deg)' }}
          />
        </motion.div>
      </Stack>

      <Typography variant="h4" component="p" color="primary.main">
        <FormattedNumber value={score} />
      </Typography>

      <Typography
        variant="h6Secondary"
        component="p"
        color="neutral01.500"
        mt="-4px"
      >
        <FormattedMessage id="label.points" values={{ amount: score }} />
      </Typography>
    </Box>
  );
};

export default Points;
