import { memo, SVGProps } from 'react';

const SvgIcoGoldenX = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 37 32" fill="none" {...props}>
    <mask
      id="mask0_3682_9743"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={37}
      height={32}
    >
      <path
        d="M18.7572 22.3781L23.2691 16.5192L34.298 30.7928H25.2372L18.7572 22.3781ZM2.45304 1.20724H11.4375L17.9946 9.69391L13.4844 15.5313L2.45304 1.20724ZM0 0L12.7219 16.5192L0.623759 32H5.91668H11.2096L17.9955 23.3677L24.6435 32H36.7553L24.03 15.5313L36.129 0H25.6219L18.7572 8.70687L12.0304 0H0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3682_9743)">
      <mask
        id="mask1_3682_9743"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={-1}
        y={0}
        width={39}
        height={32}
      >
        <path d="M37.0446 0H-0.289062V32H37.0446V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_3682_9743)">
        <rect
          x={-0.289062}
          width={37.4253}
          height={32.0788}
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgIcoGoldenX);
export default Memo;
