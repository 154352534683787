import { memo, SVGProps } from 'react';

const SvgIcoBus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 23" fill="none" {...props}>
    <g clipPath="url(#clip0_918_9714)">
      <path
        d="M21.8758 0H0V20.3196C0 21.0307 0.28244 21.712 0.78568 22.2148C1.28892 22.7176 1.97156 22.9995 2.68318 22.9995H4.98318C5.6948 22.9995 6.37744 22.7171 6.88068 22.2148C7.38392 21.712 7.66636 21.0303 7.66636 20.3196V19.9369H15.3332V20.3196C15.3332 21.0307 15.6156 21.712 16.1189 22.2148C16.6221 22.7176 17.3047 22.9995 18.0164 22.9995H20.3164C21.028 22.9995 21.7106 22.7171 22.2139 22.2148C22.7171 21.712 22.9995 21.0303 22.9995 20.3196V0H21.8753H21.8758ZM2.30046 2.2977H10.35V12.087H2.30046V2.2977ZM20.5878 20.5905C20.516 20.6623 20.418 20.7028 20.3168 20.7028H18.0168C17.8052 20.7028 17.6336 20.5312 17.6336 20.3196V17.6392H5.36682V20.3196C5.36682 20.4212 5.32634 20.5188 5.25458 20.5905C5.18282 20.6623 5.08484 20.7028 4.98364 20.7028H2.68364C2.47204 20.7028 2.30046 20.5312 2.30046 20.3196V14.3847H20.7V20.3196C20.7 20.4212 20.6595 20.5188 20.5878 20.5905ZM12.65 12.087V2.2977H20.7V12.087H12.65Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_918_9714">
        <rect width={23} height={23} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgIcoBus);
export default Memo;
