import { FormControl, FormGroup } from '@mui/material';
import React from 'react';

import { Answer as AnswerType, PostQuizAnswerResponse } from 'types/game.types';

import { Answer } from '../Answer';

interface Props {
  answers: Array<AnswerType>;
  selectedAnswer?: AnswerType | null;
  onChange(answer: AnswerType): void;
  correctAnswer?: PostQuizAnswerResponse;
  answered: boolean;
}

const AnswerList: React.FC<Props> = ({
  answers,
  selectedAnswer,
  onChange,
  correctAnswer,
  answered,
}) => {
  const isCorrect = (answer: AnswerType) => {
    return (
      correctAnswer &&
      ((correctAnswer.isCorrect && answer.id === selectedAnswer?.id) ||
        correctAnswer.correctAnswerId === answer.id)
    );
  };

  const isWrong = (answer: AnswerType) => {
    return (
      correctAnswer &&
      !correctAnswer.isCorrect &&
      answer.id === selectedAnswer?.id
    );
  };

  return (
    <FormControl fullWidth>
      <FormGroup>
        {answers.map((answer) => (
          <Answer
            correct={isCorrect(answer)}
            wrong={isWrong(answer)}
            key={answer.id}
            label={answer.answer}
            checked={answer.id === selectedAnswer?.id}
            onChange={() => onChange(answer)}
            disabled={answered}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default AnswerList;
