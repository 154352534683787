import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';

const getParticipants = async () => {
  const { data } = await apiService.getParticipants();

  return data;
};

export const useParticipants = () => {
  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.participants(),
    queryFn: () => getParticipants(),
  });

  return {
    data: data ?? [],
    isLoading,
  };
};
