import * as Sentry from '@sentry/react';
import _get from 'lodash/get';
import qs from 'query-string';
import { Store } from 'redux';

import config from 'config';

import { isTokenValid } from 'utils/token.utils';
import { inIframe } from 'utils/window.utils';

import { apiService } from 'services';
import {
  getAuthLoading,
  getRedirectRoute,
  isStoredTokenValid,
} from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { navigationActions } from 'store/navigation/navigation.slice';

export const checkForLogin = async (store: Store) => {
  const state = store.getState();

  const isValid = isStoredTokenValid(state);

  if (isValid) {
    store.dispatch(authActions.LOGIN_SUCCESS());
    return;
  }

  store.dispatch(authActions.RESET());

  const { error, access_token, code } = qs.parse(window.location.search);

  let accessToken = access_token as string | undefined;

  if (code && typeof code === 'string' && !accessToken) {
    // Clear the query
    window.history.pushState({}, document.title, window.location.pathname);

    const redirectUri = inIframe()
      ? config.IFRAME_CLIENT_URL
      : config.CLIENT_URL;

    try {
      const { data: authData } = await apiService.getAuth(code, redirectUri);

      const isValidToken = isTokenValid(authData.accessToken);

      if (isValidToken) {
        accessToken = authData.accessToken;
      }
    } catch (e) {
      store.dispatch(authActions.LOGIN_ERROR());
      store.dispatch(authActions.RESET());

      Sentry.withScope((scope) => {
        scope.setExtras({
          name: 'login-error',
          error: e,
        });
        Sentry.captureMessage('Login error');
      });

      store.dispatch(
        navigationActions.MODAL_OPEN({
          key: 'login-error',
          params: { code: 'Login error' },
        }),
      );
    }
  }

  if (accessToken && typeof accessToken === 'string') {
    // Clear the query
    window.history.pushState({}, document.title, window.location.pathname);

    try {
      const isValidToken = isTokenValid(accessToken);

      if (isValidToken) {
        store.dispatch(authActions.SET_ACCESS_TOKEN(accessToken));
        store.dispatch(authActions.LOGIN_SUCCESS());

        const redirectRoute = getRedirectRoute(state);
        if (redirectRoute) {
          store.dispatch(navigationActions.CONTENT_NAVIGATE_TO(redirectRoute));
        }
      } else {
        throw new Error('Token not valid');
      }
    } catch (e) {
      store.dispatch(authActions.LOGIN_ERROR());
      store.dispatch(authActions.RESET());

      Sentry.withScope((scope) => {
        scope.setExtras({
          name: 'login-error',
          error: e,
        });
        Sentry.captureMessage('Login error');
      });

      store.dispatch(
        navigationActions.MODAL_OPEN({
          key: 'login-error',
          params: { code: 'Login error' },
        }),
      );
    }
  } else if (error && typeof error === 'string') {
    store.dispatch(authActions.LOGIN_ERROR());
    store.dispatch(authActions.RESET());

    Sentry.withScope((scope) => {
      scope.setExtras({
        name: error ?? 'Login error',
        error: error,
      });
      Sentry.captureMessage(error ?? 'Login error');
    });

    store.dispatch(
      navigationActions.MODAL_OPEN({
        key: 'login-error',
        params: { code: error ?? 'Login error' },
      }),
    );
  } else {
    const isLoggingIn = getAuthLoading(state);
    if (isLoggingIn) {
      store.dispatch(authActions.LOGIN_ERROR());
    }
  }
};
