import {
  Box,
  ButtonBase,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import cimImage from 'assets/png/ico-logo-cim.png';
import dpgImage from 'assets/png/ico-logo-dpg.png';
import jepImage from 'assets/png/ico-logo-jep.png';

import { inIframe } from 'utils/window.utils';

import { IcoVtmGoColor } from 'components/@icons/custom';

const VtmGoButton = styled(ButtonBase)(({ theme }) => ({
  height: 38,
  borderRadius: 47,
  fontWeight: 700,
  fontSize: 14,
  border: '3px solid #FFFFFF',
  padding: theme.spacing(2),
  backgroundColor: 'rgb(0, 0, 0)',
  '&:hover': {
    backgroundColor: 'rgb(51, 51, 51)',
  },
}));

const FooterLink = styled(Link)(() => ({
  color: 'rgba(255, 255, 255, 0.72)',
  '&:hover': {
    color: 'rgba(255, 255, 255, 0.8)',
  },
}));

const VTMFooter = () => {
  const intl = useIntl();

  return (
    <Box component="footer" display={{ md: inIframe() ? 'block' : 'none' }}>
      <Box
        bgcolor="#000"
        position="relative"
        width="100%"
        height={96}
        px={{ xs: 3, md: 6 }}
        py={2}
        flexDirection={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          gap={{ xs: 1, md: 3 }}
        >
          <Typography
            typography="paragraph2"
            fontWeight={700}
            fontSize={{ xs: 12, md: 14 }}
          >
            Kijk live, uitgesteld of gewoon lekker bingewatchen
          </Typography>
          <VtmGoButton
            onClick={() => window.open('https://vtmgo.be', '_blank')}
          >
            Kijk op <IcoVtmGoColor fontSize={60} />
          </VtmGoButton>
        </Stack>
        <Stack gap={3} direction="row" display={{ xs: 'none', md: 'flex' }}>
          <FooterLink
            target="_blank"
            underline="hover"
            href="https://www.dpgmedia.be/nl/gebruiksvoorwaarden"
          >
            Gebruiksvoorwaarden
          </FooterLink>
          <FooterLink
            target="_blank"
            underline="hover"
            href={intl.formatMessage({ id: 'links.privacy_policy' })}
          >
            <FormattedMessage id={'general.privacy_policy.label'} />
          </FooterLink>
          <FooterLink
            target="_blank"
            underline="hover"
            href={intl.formatMessage({ id: 'links.cookie_policy' })}
          >
            <FormattedMessage id={'general.cookie_policy.label'} />
          </FooterLink>
          <FooterLink
            target="_blank"
            underline="hover"
            href="https://www.dpgmedia.be/nl/wedstrijdreglement"
          >
            Wedstrijdreglement
          </FooterLink>
        </Stack>
      </Box>
      <Box
        bgcolor="#fff"
        height={{ md: 100 }}
        py={1}
        px={6}
        flexDirection={{ xs: 'column', md: 'row' }}
        display="flex"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'space-between' }}
      >
        <img src={dpgImage} alt="logo DPG media" width={102} height={53} />
        <Stack
          gap={{ xs: 2, md: 3 }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          mt={{ xs: 2, md: 0 }}
        >
          <Typography
            color="rgba(60, 60, 60, 0.72)"
            fontSize={14}
            textAlign="center"
          >
            DPG Media NV - Mediaplein 1, 2018 Antwerpen - RPR Antwerpen nr.
            0432.306.234
          </Typography>
          <a href="https://www.jep.be/" target="_blank" rel="noreferrer">
            <img src={jepImage} alt="logo DPG media" width={40} height={28} />
          </a>
          <a
            href="https://www.cim.be/nl/internet/disclaimer-cim-internet"
            target="_blank"
            rel="noreferrer"
          >
            <img src={cimImage} alt="logo DPG media" width={96} height={22} />
          </a>
        </Stack>
      </Box>
    </Box>
  );
};

export default VTMFooter;
