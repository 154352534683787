import { Box, Link, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config/env';

interface Props {
  code: string;
}

const LoginErrorContent = ({ code }: Props) => {
  let errorMessageId = 'login.error.general.description';
  if (code === 'missing_first_name') {
    errorMessageId = 'login.error.missing_firstname.description';
  }

  return (
    <Stack zIndex={25} gap={4} pb={10} alignItems="center">
      <Typography variant="h2">
        <FormattedMessage id="login.error.title" />
      </Typography>
      <Box mt={2} mb={5} maxWidth={350} margin="auto" textAlign="center">
        <Typography variant="paragraph1">
          <FormattedMessage id={errorMessageId} />
        </Typography>
      </Box>
      {code === 'missing_first_name' ? (
        <Link href={config.EXTERNAL_PROFILE_PAGE_URL} target="_blank">
          <FormattedMessage id="login.error.missing_firstname.change.button" />
        </Link>
      ) : (
        <span>
          <Typography variant="h6" component="span">
            Error:
          </Typography>
          &nbsp;
          <Typography variant="h6Secondary" component="span">
            {code}
          </Typography>
        </span>
      )}
    </Stack>
  );
};

export default LoginErrorContent;
