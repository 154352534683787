import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { GoldenXPin } from 'types/golden-x.types';
import { PinPlacement } from 'types/user.types';

type Position = { latitude: number; longitude: number };

const postUserGoldenPin = async (
  userId: string,
  episodeId: string,
  position: Position,
) => {
  const { data } = await apiService.postUserGoldenPin(
    userId,
    episodeId,
    position,
  );

  return data;
};

export const useUserGoldenPin = (episodeId = '') => {
  const { userId = '' } = useAuth();
  const queryClient = useQueryClient();

  const setQueryDataPin = useCallback(
    (data: PinPlacement) => {
      const previousGuesses =
        queryClient.getQueryData<Array<GoldenXPin>>(QueryKeys.goldenX.all()) ??
        undefined;

      // Create a deep copy of previousGuesses
      const newData = (previousGuesses ?? []).map((guess) => ({
        ...guess,
        goldenX: guess.goldenX ? { ...guess.goldenX } : undefined,
        episode: { ...guess.episode },
      }));

      // find the item of previous guesses where episode.id matches episodeId and set the new coordinates
      const index = newData.findIndex((item) => item.episode.id === episodeId);

      if (index !== -1) {
        newData[index].goldenX = {
          latitude: data.latitude,
          longitude: data.longitude,
          userId,
          episodeId,
          createdAt: new Date().toISOString(),
        };

        queryClient.setQueryData(QueryKeys.goldenX.all(), newData);
      }
    },
    [episodeId, queryClient, userId],
  );

  const { mutate, error: goldenPinSetError } = useMutation<
    PinPlacement,
    AxiosError<{ errors?: string }>,
    Position,
    { previousGuesses?: Array<GoldenXPin> }
  >({
    mutationFn: (data) => postUserGoldenPin(userId, episodeId, data),
    onMutate: (data) => {
      const previousGuesses =
        queryClient.getQueryData<Array<GoldenXPin>>(QueryKeys.goldenX.all()) ??
        undefined;

      setQueryDataPin({
        latitude: data.latitude,
        longitude: data.longitude,
        userId,
        episodeId,
        createdAt: new Date().toISOString(),
      });

      return { previousGuesses };
    },
    onSuccess: (data) => {
      setQueryDataPin(data);
    },
    onError: (_err, _id, ctx) => {
      queryClient.setQueryData(
        QueryKeys.goldenX.all(),
        ctx?.previousGuesses ?? null,
      );
    },
  });

  return { setGoldenPin: mutate, goldenPinSetError };
};
