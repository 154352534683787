import { GoldenXPin, GoldenXTip } from 'types/golden-x.types';
import { PinPlacement, ScoreBreakDown, User } from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService) {
    return this.api.get<User>('/users/me', { authEnabled: true });
  },
  getScoreBreakdown(this: RestApiService, userId: string, reveal?: string) {
    return this.api.get<ScoreBreakDown>(`/users/${userId}/score-breakdown`, {
      authEnabled: true,
      params: { reveal },
    });
  },
  getUserPin(this: RestApiService, userId: string, episodeId: string) {
    return this.api.get<PinPlacement>(
      `/users/${userId}/episodes/${episodeId}/pin`,
      { authEnabled: true },
    );
  },
  postUserPin(
    this: RestApiService,
    userId: string,
    episodeId: string,
    position: { latitude: number; longitude: number },
  ) {
    return this.api.post<PinPlacement>(
      `/users/${userId}/episodes/${episodeId}/pin`,
      position,
      { authEnabled: true },
    );
  },
  postUserGoldenPin(
    this: RestApiService,
    userId: string,
    episodeId: string,
    position: { latitude: number; longitude: number },
  ) {
    return this.api.post<PinPlacement>(
      `/users/${userId}/episodes/${episodeId}/golden-x/pin`,
      position,
      { authEnabled: true },
    );
  },
  getGoldenXGuesses(this: RestApiService, userId: string) {
    return this.api.get<Array<GoldenXPin>>(`/users/${userId}/golden-x/pins`, {
      authEnabled: true,
    });
  },
  getGoldenXTips(this: RestApiService, userId: string, episodeId: string) {
    return this.api.get<GoldenXTip>(
      `/users/${userId}/episodes/${episodeId}/golden-x/tips`,
      {
        authEnabled: true,
      },
    );
  },
};
