import { useSelector } from 'react-redux';

import {
  getAuthLoading,
  getUserId,
  isStoredTokenValid,
} from 'store/auth/auth.selectors';

export const useTokenAuth = () => {
  const isLoading = useSelector(getAuthLoading);
  const userId = useSelector(getUserId);
  const isLoggedIn = useSelector(isStoredTokenValid);

  return { isLoading, userId, isLoggedIn };
};
