import { memo, SVGProps } from 'react';

const SvgIcoChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 21 11" fill="none" {...props}>
    <path
      d="M20.6182 -0.00049305H13.7454H10.3091H6.87271H-9.53674e-06L10.3091 10.3086L20.6182 -0.00049305Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoChevron);
export default Memo;
