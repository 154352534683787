export enum EventName {
  PartnerOne = 'bx-partner-1',
  PartnerTwo = 'bx-partner-2',
  PastEpisode = 'bx-episode',
  NextEpisode = 'bx-gamecenter',
  EpisodeMap = 'bx-episode-map',
  PinPlace = 'bx-game-pin',
  PastEpisodePlayAnonymous = 'bx-gamecenter-episode',
  PastEpisodePlay = 'bx-play-game',
  PoiMap = 'bx-poi-map',
  PoiList = 'bx-poi-list',
  PastEpisodeWatch = 'bx-past-episode-watch',
  GuessArea = 'bx-game-mini',
  ChoosePartner = 'bx-game-candidate',
  PinAdd = 'bx-game-pin-add',
  PinChange = 'bx-game-pin-change',
  PinLock = 'bx-game-pin-confirm',
  GoldenPinAdd = 'bx-game-golden-pin-add',
  GoldenPinChange = 'bx-game-golden-pin-change',
  GoldenPinLock = 'bx-game-golden-pin-confirm',
  Guess = 'bx-game-mini-geoguess',
  Quiz = 'bx-game-mini-quiz',
  QuizAnswer = 'bx-game-mini-quiz-{count}',
  GuessAnswer = 'bx-game-mini-geoguess-{count}',
  PastEpisodeShortform = 'bx-game-past-tips-mini-shortform',
  PartnerSelect = 'bx-game-candidate-select',
  Rules = 'bx-tutorial',
  ShareGuess = 'bx-share-guess',
  ShareScore = 'bx-share-score',
  Leaderboard = 'bx-leaderboard',

  // VTM Specific
  PastEpisodeVtmGo = 'vtm-go-episode',
  PastEpisodeVtmGoTips = 'vtm-go-cpbd',
}

export enum PageView {
  MiniGames = 'mini-games',
  Quiz = 'mini-game-quiz',
  Poi = 'poi',
  Partner = 'partner-in-crime',
  Guess = 'mini-game-geoguess',
  PastEpisode = 'episode',
  NextEpisode = 'game',
  Program = 'program',
  GoldenX = 'golden-x',
  Map = 'map-game',
  Rules = 'tutorial',
  Leaderboard = 'leaderboard',
  GoldenXIntro = 'golden-x-intro',
  VideoDetail = 'video-detail',
}

export const DATALAYER_NAME = 'dataLayer';
