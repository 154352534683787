import { InternalAxiosRequestConfig } from 'axios';

import config from 'config';

import { isDev } from 'utils/env.utils';

import store from 'store';
import { getAccessToken } from 'store/auth/auth.selectors';
import { getLocale } from 'store/intl/intl.selectors';

const success = (connection: InternalAxiosRequestConfig) => {
  const state = store.store.getState();
  const token = getAccessToken(state);

  if (connection.headers) {
    if (token && connection.authEnabled && config.AUTH_TYPE === 'token') {
      connection.headers.Authorization = `Bearer ${token}`;
    } else if (isDev() && connection.authEnabled) {
      if (config.BRAND === 'vtm') {
        connection.headers['X-FAKE-USER-ID'] =
          'fb6e42a1c5af4299b08484ccd7f43567';
      } else if (config.BRAND === 'dr') {
        connection.headers['X-FAKE-USER-ID'] = '123';
      }
    }

    const locale = getLocale(state);
    connection.headers['Accept-Language'] = locale.toUpperCase();
  }
  return connection;
};

const error = (connection: InternalAxiosRequestConfig) =>
  Promise.reject(connection);

export default { success, error };
