import { memo, SVGProps } from 'react';

const SvgIcoPoiSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 24" fill="none" {...props}>
    <path
      d="M1.90735e-06 12H7.33333L11 15.6667L14.6667 12H22L11 23L1.90735e-06 12Z"
      fill="#EBD457"
    />
    <path
      d="M1.90735e-06 1H7.33333L11 4.66667L14.6667 1H22L11 12L1.90735e-06 1Z"
      fill="#EBD457"
    />
  </svg>
);
const Memo = memo(SvgIcoPoiSelected);
export default Memo;
