import { Box, List, Stack, Typography } from '@mui/material';

import { LeaderboardUser } from 'types/leaderboard.types';

import { LeaderboardListItem } from '../LeaderboardListItem';

interface Props {
  leaderboard: Array<LeaderboardUser>;
  userLeaderboard?: Array<LeaderboardUser>;
}

const Leaderboard = ({ leaderboard, userLeaderboard }: Props) => {
  return (
    <Box>
      <List>
        {leaderboard.map((u) => (
          <LeaderboardListItem key={u.id} user={u} />
        ))}
      </List>
      {userLeaderboard && (
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography>...</Typography>
            <Typography>...</Typography>
          </Stack>
          <List>
            {userLeaderboard.map((u) => (
              <LeaderboardListItem key={u.id} user={u} />
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Leaderboard;
