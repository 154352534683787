import { EMBEDDED_URLS } from './constants';

type EmbedOptions = {
  autoPlay?: boolean;
};

export const transformEmbedUrl = (url: string, options?: EmbedOptions) => {
  const parameters = new URLSearchParams(url.split('?')[1]);

  if (url.includes(EMBEDDED_URLS.vtm)) {
    if (!parameters.has('automute')) {
      parameters.append('automute', 'true');
    }
    if (!parameters.has('autoplay')) {
      parameters.append('autoplay', options?.autoPlay ? 'true' : 'false');
    }
    if (!parameters.has('pip')) {
      parameters.append('pip', 'false');
    }
    if (!parameters.has('single_play')) {
      parameters.append('single_play', 'false');
    }
  }

  return `${url.split('?')[0]}?${parameters.toString()}`;
};
