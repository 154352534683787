import { RichTextField } from '@prismicio/types';

export enum Games {
  Quiz = 'quiz',
  Guesser = 'guesser',
}

export type LandMark = {
  position: { lat: number; lng: number };
  pov?: { heading: number; pitch?: number };
  image?: string;
};

export type Coordinate = {
  longitude: number;
  latitude: number;
};

export type GuessGameUserAnswer = {
  isCorrect: boolean;
  distanceInMeters: number;
  coordinate: Coordinate;
};

export type GuessGameCoordinate = {
  id: string;
  coordinate: Coordinate;
  heading?: number;
  pitch?: number;
  userAnswer: GuessGameUserAnswer | null;
  fallbackImageUrl?: string;
};

export type Answer = {
  id: number;
  answer: string;
};

export type QuizUserAnswer = {
  id: number;
  isCorrect: boolean;
};

export type QuizQuestion = {
  id: string;
  question: RichTextField;
  possibleAnswers: Array<Answer>;
  userAnswer: QuizUserAnswer | null;
};

export type Circle = {
  coordinate: Coordinate;
  radius: number;
};

export type MiniGames = {
  quiz: Array<QuizQuestion>;
  guessGame: Array<GuessGameCoordinate>;
  circle: Circle;
  guessGameFallbackEnabled?: boolean;
};

export type PostAnswerParams = {
  userId: string;
  episodeId: string;
};

export type PostQuizAnswerParams = PostAnswerParams & {
  questionId: string;
  answerId: number;
};

export type PostQuizAnswerResponse = {
  correctAnswerId: number;
  isCorrect: boolean;
};

export type PostGuessGameAnswerParams = PostAnswerParams & {
  coordinateId: string;
  coordinates: Coordinate;
};

export type PostGuessGameAnswerResponse = {
  isCorrect: boolean;
  distanceInMeters: number;
};
