import { memo, SVGProps } from 'react';

const SvgIcoGuess = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" {...props}>
    <g opacity={0.5}>
      <rect
        x={0.636364}
        y={0.636364}
        width={12.7273}
        height={12.7273}
        stroke="white"
        strokeWidth={1.27273}
      />
      <path
        d="M6.82997 15.4809L3.24048 12.0107H10.4493L6.82997 15.4809Z"
        fill="#1E1E1E"
      />
      <path
        d="M3.50119 14H5.30421L6.84209 15.485L8.37983 14H10.1829L6.84209 17.1819L3.50119 14Z"
        fill="white"
      />
      <path
        d="M6.16573 9.54492V8.12456H7.68536V9.54492H6.16573ZM7.90682 6.35292C8.085 6.32747 8.23009 6.28165 8.34209 6.21547C8.45409 6.14419 8.51009 6.0271 8.51009 5.86419C8.51009 5.70129 8.44136 5.58165 8.30391 5.50529C8.16645 5.42892 8.00609 5.39074 7.82282 5.39074L7.189 5.37547L6.64682 5.39074C6.41773 5.39074 6.24464 5.43401 6.12754 5.52056C6.01554 5.6071 5.95954 5.75729 5.95954 5.9711C5.95954 6.08819 5.96464 6.16456 5.97482 6.20019H4.63845C4.63845 5.54856 4.73773 5.09292 4.93627 4.83329C5.13482 4.57365 5.53954 4.44383 6.15045 4.44383L7.19664 4.40565C7.32391 4.40565 7.45882 4.40819 7.60136 4.41329C7.74391 4.41329 7.89154 4.41583 8.04427 4.42092C8.23773 4.42092 8.49736 4.44638 8.82318 4.49729C9.149 4.5431 9.39336 4.67801 9.55627 4.90201C9.71918 5.12601 9.80064 5.4391 9.80064 5.84129V5.95583C9.80064 6.19001 9.78536 6.38347 9.75482 6.53619C9.73445 6.67365 9.66064 6.81365 9.53336 6.95619C9.40609 7.09874 9.05227 7.22347 8.47191 7.33038C7.89154 7.43729 7.59373 7.54929 7.57845 7.66638L7.58609 7.75038H6.27264C6.27264 7.15474 6.36682 6.80347 6.55518 6.69656C6.74864 6.58456 7.19918 6.47001 7.90682 6.35292Z"
        fill="white"
      />
    </g>
  </svg>
);
const Memo = memo(SvgIcoGuess);
export default Memo;
