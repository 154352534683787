import { memo, SVGProps } from 'react';

const SvgIcoNumber = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 31 24" fill="none" {...props}>
    <path
      d="M10.4549 0.619385H20.545L15.5 6.89132L10.4549 0.619385Z"
      fill="currentColor"
    />
    <path
      d="M20.545 23.4615H10.4549L15.5 17.1874L20.545 23.4615Z"
      fill="currentColor"
    />
    <path
      d="M0 4.55997L5.96805 12.0512L0 19.5402H5.49678L11.4648 12.0512L5.49678 4.55997H0Z"
      fill="currentColor"
    />
    <path
      d="M31 19.5402L25.032 12.049L31 4.55997H25.5032L19.5352 12.049L25.5032 19.5402H31Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoNumber);
export default Memo;
