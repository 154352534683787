import { memo, SVGProps } from 'react';

const SvgIcoCorner = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" {...props}>
    <path
      d="M11.9992 0.998808L9.33283 3.66522L2.66641 3.66522L2.66641 10.3316L0 12.998V0.998803L11.9992 0.998808Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIcoCorner);
export default Memo;
