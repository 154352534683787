import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import { useSelector } from 'react-redux';

import config from 'config';

import { getTrackingEnabled } from 'store/settings/settings.selectors';
import { DATALAYER_NAME } from 'types/analytics.types';

export const gtmParams: ISnippetsParams = {
  id: config.ANALYTICS?.tag_manager_id || '',
  dataLayer: {},
  dataLayerName: DATALAYER_NAME,
  injectScript: !!config.ANALYTICS?.tag_manager_id.length ? true : false,
};

interface Props {
  children: React.ReactNode;
}

export const AnalyticsProvider = ({ children }: Props) => {
  const trackingEnabled = useSelector(getTrackingEnabled);

  return (
    <GTMProvider state={{ ...gtmParams, injectScript: trackingEnabled }}>
      {children}
    </GTMProvider>
  );
};
