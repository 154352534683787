import storage from 'redux-persist/lib/storage';

import config from 'config';

import initializeApp from 'services/boot/boot';
import { generateStore } from 'services/redux';

import startAuthListeners from './auth/auth.listeners';
import startNavigationListeners from './navigation/navigation.listeners';
import combinedReducers from './reducers';

const store = generateStore(combinedReducers, {
  initApp: async (store) => {
    startNavigationListeners();
    startAuthListeners();
    await initializeApp(store);
  },
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  persistConfig: {
    key: `bx-cache-state-${config.BRAND}`,
    keyPrefix: '',
    storage,
    version: 2,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: ['intl', 'auth', 'settings'],
    // add subsets to persist nested data
    transforms: [],
  },
});

export default store;
