import { memo, SVGProps } from 'react';

const SvgIcoLockCirclePrimary = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect
      x={1}
      y={1}
      width={18}
      height={18}
      rx={9}
      fill="black"
      stroke="black"
      strokeWidth={2}
    />
    <path
      d="M13.833 14.2673H6.16699V8.51782H13.833V14.2673ZM8.0835 8.51782V6.60132C8.0835 5.54341 8.94305 4.68481 10 4.68481C11.057 4.68481 11.9165 5.54341 11.9165 6.60132V8.51782H12.8748V6.60132C12.8748 5.01445 11.5878 3.72656 10 3.72656C8.41218 3.72656 7.12524 5.01445 7.12524 6.60132V8.51782H8.0835Z"
      fill="#EBD457"
    />
  </svg>
);
const Memo = memo(SvgIcoLockCirclePrimary);
export default Memo;
