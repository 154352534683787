import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { shuffle } from 'lodash';
import { motion, Transition, Variants } from 'motion/react';
import { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAnalytics, usePinPlacement } from 'hooks';
import { useEpisodes, usePartner, useSelectPartner } from 'queries';
import { useParticipants } from 'queries/participants/useParticipants';
import { navigationActions } from 'store/navigation/navigation.slice';
import theme from 'theme';
import { EventName } from 'types/analytics.types';
import { Participant } from 'types/episode.types';

import { AnimatedButton } from 'components/@common';
import { ParticipantAvatar } from 'components/@participants';
import LoadingState from 'components/@states/LoadingState';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

interface Props {
  withPin?: boolean;
}

const SelectPartnerContent = ({ withPin }: Props) => {
  const { trackEvent } = useAnalytics();
  const { nextEpisode } = useEpisodes();
  const { start } = usePinPlacement();
  const { data: participants, isLoading } = useParticipants();
  const { data: partner } = usePartner(nextEpisode?.id);
  const { selectPartner } = useSelectPartner();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const pinButtonRef = useRef<HTMLDivElement>(null);

  const handleSelectPartner = (participant: Participant) => {
    if (nextEpisode) {
      trackEvent({ eventName: EventName.PartnerSelect });

      if (!withPin) {
        dispatch(navigationActions.MODAL_CLOSE());
      } else if (pinButtonRef.current) {
        pinButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      }

      selectPartner({
        episodeId: nextEpisode.id,
        participant,
      });
    }
  };

  const handleSetPin = () => {
    dispatch(navigationActions.MODAL_CLOSE());
    start();
  };

  const shuffledParticipants = useMemo(
    () => shuffle(participants),
    [participants],
  );

  return (
    <Stack
      alignItems="center"
      gap={2}
      component={motion.div}
      initial="initial"
      animate="animate"
    >
      <motion.div variants={animationVariant} transition={transition(0)}>
        <Typography variant={isSmall ? 'h4' : 'h2'}>
          <FormattedMessage id="select_partner.modal.title" />
        </Typography>
      </motion.div>
      <motion.div variants={animationVariant} transition={transition(1)}>
        <Typography mb={{ xs: 5, md: 3 }}>
          <FormattedMessage id="select_partner.modal.description" />
        </Typography>
      </motion.div>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <motion.div variants={animationVariant} transition={transition(2)}>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 8 }}
              rowSpacing={{ xs: 4, md: 8 }}
              sx={{ mb: 2 }}
            >
              {/* Shuffle participants so the same ones don't always come first */}
              {shuffledParticipants.map((participant) => (
                <Grid key={participant.id} item xs={6} md={2.4}>
                  <ParticipantAvatar
                    participant={participant}
                    selected={participant.id === partner?.id}
                    onClick={handleSelectPartner}
                  />
                </Grid>
              ))}
            </Grid>
          </motion.div>
          {withPin && (
            <motion.div
              variants={animationVariant}
              transition={transition(3)}
              id="pin-button"
              ref={pinButtonRef}
            >
              <AnimatedButton onClick={handleSetPin}>
                <FormattedMessage
                  id={
                    partner
                      ? 'select_partner.modal.place_pin.with_partner.button'
                      : 'select_partner.modal.place_pin.without_partner.button'
                  }
                />
              </AnimatedButton>
            </motion.div>
          )}
        </>
      )}
    </Stack>
  );
};

export default SelectPartnerContent;
