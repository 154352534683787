import { Store } from 'store/reducers';

export const getContentRoute = (state: Store) => {
  return state.navigation.contentRoute;
};

export const getPlacingPinType = (state: Store) => {
  return state.navigation.pin?.type;
};

export const getIsPlacingPin = (state: Store) => {
  return !!state.navigation.pin?.isPlacing;
};

export const getIsLockingPin = (state: Store) => {
  return !!state.navigation.pin?.isLocking;
};

export const getContentHistory = (state: Store) => {
  return state.navigation.history;
};

export const getModal = (state: Store) => {
  return state.navigation.modal;
};
