import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookieConsentValue } from 'react-cookie-consent';

import config from 'config/env';

import { Feature } from 'types/features.types';

export interface SettingsState {
  trackingEnabled: boolean;
  didSeeGoldenXIntro?: boolean;
  watchedTips?: Array<string>;
}

const initialState: SettingsState = {
  trackingEnabled:
    !config.FEATURES.includes(Feature.CookieConsent) ||
    getCookieConsentValue() === 'true',
  didSeeGoldenXIntro: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    CHANGE_TRACKING: (
      state,
      { payload }: PayloadAction<SettingsState['trackingEnabled']>,
    ) => {
      state.trackingEnabled = payload;
    },
    SET_GOLDEN_X_INTRO_SEEN: (state) => {
      state.didSeeGoldenXIntro = true;
    },
    SET_DID_WATCH_TIP: (state, { payload }: PayloadAction<string>) => {
      if (!state.watchedTips) {
        state.watchedTips = [];
      }

      if (!state.watchedTips.includes(payload)) {
        state.watchedTips.push(payload);
      }
    },
  },
});

export const { actions: settingsActions } = settingsSlice;

export default settingsSlice.reducer;
