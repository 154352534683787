import { Box, Typography } from '@mui/material';

import shareBg from 'assets/branded/jpg/img-share-score.jpg';

import { ShareRenderer } from '../ShareRenderer';

interface Props {
  score: number;
  onComplete: (image: string) => void;
}

const ShareScore = ({ score, onComplete }: Props) => {
  let fontSize = 120;
  if (score > 100000) {
    fontSize = 90;
  } else if (score > 1000) {
    fontSize = 100;
  }

  return (
    <ShareRenderer id={`guess-${score}`} onComplete={onComplete} render>
      <Box
        width={1080}
        height={1920}
        position="relative"
        overflow="hidden"
        bgcolor="#000"
      >
        <Box
          component="img"
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          top={0}
          width="100%"
          height="100%"
          src={shareBg}
        />
        <Box
          position="absolute"
          top={900}
          left={170}
          right={170}
          bottom={860}
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1" fontSize={fontSize} color="brand01.100">
            {score.toLocaleString('nl')}
          </Typography>
        </Box>
      </Box>
    </ShareRenderer>
  );
};

export default ShareScore;
