import { Box, Stack, Typography } from '@mui/material';
import { motion, Variants } from 'motion/react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import infoImage from 'assets/branded/jpg/img-visual-golden-x.jpg';

import { useEpisodes } from 'queries';
import { navigationActions } from 'store/navigation/navigation.slice';

import { AnimatedButton } from 'components/@common';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.7,
    },
  },
};

const GoldenXIntroContent = () => {
  const dispatch = useDispatch();
  const { currentEpisode, episodes } = useEpisodes();

  const handleButtonClick = () => {
    if (!episodes?.length) return;

    dispatch(navigationActions.MODAL_CLOSE());
    dispatch(
      navigationActions.CONTENT_NAVIGATE_TO({
        key: 'golden-x',
        params: { episodeId: currentEpisode?.id || episodes[0].id },
      }),
    );
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      transition={{ staggerChildren: 0.4 }}
      style={{ flex: 1, display: 'flex' }}
    >
      <Stack
        flex={1}
        textAlign="center"
        alignItems="center"
        sx={{ textWrap: 'balance' }}
      >
        <Typography
          component={motion.h2}
          variants={animationVariant}
          variant="h3"
          mb={2}
        >
          <FormattedMessage
            id="golden_x.modal.title"
            values={{
              b: (value) => (
                <Typography
                  variant="inherit"
                  component="span"
                  color="brand01.100"
                >
                  {value}
                </Typography>
              ),
            }}
          />
        </Typography>
        <Typography
          component={motion.p}
          variants={animationVariant}
          variant="paragraph2"
          fontWeight={400}
          mb={4}
          maxWidth={450}
        >
          <FormattedMessage id="golden_x.modal.info" />
        </Typography>
        <Box
          component={motion.img}
          variants={animationVariant}
          src={infoImage}
          ml={-40}
          mr={-40}
          height={400}
          my={-10}
          zIndex={-1}
        />
        <motion.div variants={animationVariant}>
          <AnimatedButton sx={{ mt: 4 }} onClick={handleButtonClick}>
            <FormattedMessage id="golden_x.modal.button" />
          </AnimatedButton>
        </motion.div>
      </Stack>
    </motion.div>
  );
};

export default GoldenXIntroContent;
