import { memo, SVGProps } from 'react';

const SvgIcoBackwards = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="1em" viewBox="0 0 17 14" width="1em" {...props}>
    <g fill="#fff">
      <path d="m16.332.00078201v4.59999799l-2.5555 2.3 2.5555 2.3v4.60002l-7.66664-6.90002z" />
      <path d="m8.66602.00078201v4.59999799l-2.55556 2.3 2.55556 2.3v4.60002l-7.666671-6.90002z" />
    </g>
  </svg>
);
const Memo = memo(SvgIcoBackwards);
export default Memo;
