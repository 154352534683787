import { memo, SVGProps } from 'react';

const SvgIcoArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 11" fill="none" {...props}>
    <path
      d="M0.691397 0H7.56412L11.0005 3.43636L14.4368 0H21.3096L11.0005 10.3091L0.691397 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoArrow);
export default Memo;
