import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import config from 'config/env';

import { authActions } from 'store/auth/auth.slice';
import { ContentRoute } from 'store/navigation/navigation.slice';

import { useCookieAuth } from './useCookieAuth';
import { useTokenAuth } from './useTokenAuth';

export const useAuth = () => {
  const dispatch = useDispatch();

  const login = useCallback(
    (route?: ContentRoute) => {
      dispatch(authActions.LOGIN_START(route));
    },
    [dispatch],
  );

  const cookieAuth = useCookieAuth();
  const tokenAuth = useTokenAuth();

  if (config.AUTH_TYPE === 'cookie') {
    return { login, ...cookieAuth };
  } else if (config.AUTH_TYPE === 'token') {
    return { login, ...tokenAuth };
  } else {
    return { login, isLoading: false, userId: undefined, isLoggedIn: false };
  }
};
