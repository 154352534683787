import { Box, Stack, Typography } from '@mui/material';
import { motion, Transition, Variants } from 'motion/react';

import { Poi } from 'types/episode.types';

import { VtmGoCard } from 'components/@cards';
import { CornerItem, FadeImage, VtmVideo } from 'components/@common';
import { PrismicText } from 'components/@common/PrismicText';

interface Props {
  poi: Poi;
}

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const PoiDetailContent = ({ poi }: Props) => {
  return (
    <motion.div initial="initial" animate="animate">
      <Stack zIndex={25} gap={4}>
        <motion.div variants={animationVariant} transition={transition(0)}>
          <Typography variant="h2Secondary" component="h1">
            {poi.title}
          </Typography>
        </motion.div>
        <motion.div variants={animationVariant} transition={transition(1)}>
          <CornerItem cornerOpacity={0.2} overflow>
            {poi.videoId && <VtmVideo videoId={poi.videoId} />}
            {poi.imageUrl && !poi.videoId && (
              <FadeImage
                src={poi.imageUrl}
                width="100%"
                height={{ xs: 165, sm: 370 }}
              />
            )}
          </CornerItem>
        </motion.div>

        <Stack direction={{ xs: 'column', md: 'row-reverse' }}>
          {poi.poiVideo?.url && (
            <motion.div variants={animationVariant} transition={transition(2)}>
              <Box
                flexShrink={0}
                width={{ xs: '100%', md: 220 }}
                height={{ xs: 124, md: 145 }}
              >
                <VtmGoCard
                  url={poi.poiVideo.url}
                  title={poi.poiVideo.title}
                  image={poi.poiVideo.imageUrl ?? poi.imageUrl}
                  duration={poi.poiVideo.durationInSeconds}
                  subtitle={poi.poiVideo.subtitle}
                />
              </Box>
            </motion.div>
          )}

          {poi.body && (
            <motion.div variants={animationVariant} transition={transition(2)}>
              <PrismicText text={poi.body} />
            </motion.div>
          )}
        </Stack>
      </Stack>
    </motion.div>
  );
};

export default PoiDetailContent;
