import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, styled, useTheme } from '@mui/material';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from 'config';

import { getBoundsZoomLevel, googleMapsAvailable } from 'utils/map.utils';

import { getFinishedInitialMapAnimation } from 'store/map/map.selectors';
import { mapActions } from 'store/map/map.slice';

import Map from '../Map';
import { useMap } from '../MapProvider';

const MapStyled = styled(Box)`
  .gm-bundled-control-on-bottom {
    bottom: 140px !important;
  }
  .gm-control-active {
    background-color: #000000 !important;
    border: 1px solid #3d3d3d !important;

    > * {
      filter: brightness(2.5) !important;
    }
  }
  .gmnoprint {
    * > div {
      background-color: #000000 !important;

      &:nth-of-type(2) {
        background-color: #000000 !important;
      }
    }
  }
`;

interface GoogleMapProps {
  onIdle?: (map: google.maps.Map) => void;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  center?: google.maps.LatLngLiteral;
  bounds?: Array<google.maps.LatLngLiteral>;
  children?: ReactNode;
  restrict?: boolean;
  mapKey: string;
  mapId?: string;
  disableMovement?: boolean;
  zoomControl?: boolean;
  disableDoubleClickZoom?: boolean;
}

const MAP_BOUNDS = {
  north: 72.003226,
  south: 23.86448,
  west: -41.430822,
  east: 48.249056,
};

const MapWrapper = ({
  onClick,
  onIdle,
  children,
  bounds,
  restrict,
  mapKey,
  disableMovement,
  mapId = config.GOOGLE_MAPS_IDS.dark,
  zoomControl,
  disableDoubleClickZoom,
}: GoogleMapProps) => {
  const finishedMapAnimation = useSelector(getFinishedInitialMapAnimation);
  const [map] = useMap();
  const wrapRef = useRef<HTMLDivElement>(null);
  const didStartAnim = useRef(false);
  const didFinishCallback = useRef(false);
  const dispatch = useDispatch();

  const mapBounds = useCallback(() => {
    if (
      !googleMapsAvailable() ||
      !bounds?.length // Ensure `bounds` is defined and has elements
    ) {
      return; // Exit early if any condition fails
    }

    const mapBounds = new google.maps.LatLngBounds();
    //  Go through each...
    for (let i = 0; i < bounds.length; i++) {
      //  And increase the bounds to take this point
      mapBounds.extend(bounds[i]);
    }

    return mapBounds;
  }, [bounds]);

  const cameraOptionsRef = useRef<google.maps.CameraOptions>({
    tilt: 0,
    heading: 0,
    zoom: 5,
    center: {
      lat: mapBounds()?.getCenter().lat() ?? config.MAPS_INITIAL_CENTER.lat,
      lng: mapBounds()?.getCenter().lng() ?? config.MAPS_INITIAL_CENTER.lng,
    },
  });

  useEffect(() => {
    if (map) {
      map.setOptions({ draggable: !disableMovement });
    }
  }, [map, disableMovement]);

  const animateMap = useCallback(() => {
    const mapB = mapBounds();

    if (!map || !mapB) return;

    let frame = requestAnimationFrame(animate);

    function animate(time: number) {
      frame = requestAnimationFrame(animate);
      update(time);
    }
    map.setCenter(mapB.getCenter());
    new Tween(cameraOptionsRef.current) // Create a new tween that modifies 'cameraOptions'.
      .to(
        {
          zoom: Math.min(
            getBoundsZoomLevel(mapB, {
              width: wrapRef.current?.clientWidth ?? 256,
              height: wrapRef.current?.clientHeight ?? 256,
            }),
            12,
          ),
          center: {
            lat: mapB.getCenter().lat(),
            lng: mapB.getCenter().lng(),
          },
        },
        6000,
      )
      .delay(1000)

      .easing(Easing.Quartic.InOut) // Use an easing function to make the animation smooth.
      .onUpdate((_, e) => {
        map.moveCamera(cameraOptionsRef.current);
        if (e >= 0.75 && !didFinishCallback.current) {
          didFinishCallback.current = true;

          dispatch(mapActions.FINISH_INITIAL_ANIMATION());
        }
      })
      .onComplete(() => {
        cancelAnimationFrame(frame);
      })
      .start();
  }, [dispatch, map, mapBounds]);

  useEffect(() => {
    const mapB = mapBounds();
    if (!finishedMapAnimation && mapB && map && !didStartAnim.current) {
      didStartAnim.current = true;
      animateMap();
    } else if (finishedMapAnimation && !didStartAnim.current) {
      didStartAnim.current = true;
      didFinishCallback.current = true;
      dispatch(mapActions.FINISH_INITIAL_ANIMATION());
      if (map && mapB) {
        map.setOptions({
          zoom: Math.min(
            getBoundsZoomLevel(mapB, {
              width: wrapRef.current?.clientWidth ?? 256,
              height: wrapRef.current?.clientHeight ?? 256,
            }),
            12,
          ),
          center: {
            lat: mapB.getCenter().lat(),
            lng: mapB.getCenter().lng(),
          },
        });
      }
    }
  }, [finishedMapAnimation, bounds, mapBounds, map, animateMap, dispatch]);

  const handleInit = (map: google.maps.Map) => {
    const mapB = mapBounds();

    if (mapB) {
      cameraOptionsRef.current.center = {
        lat: mapB.getCenter().lat(),
        lng: mapB.getCenter().lng(),
      };
    }
    map.moveCamera(cameraOptionsRef.current);
  };

  const theme = useTheme();

  let restrictBounds = restrict;
  if (restrict && !finishedMapAnimation) {
    if (
      !!bounds?.length &&
      (!didStartAnim.current || !didFinishCallback.current)
    ) {
      restrictBounds = false;
    }
  }

  return (
    <MapStyled width="100%" height="100%" ref={wrapRef}>
      <Wrapper
        libraries={['visualization', 'geometry']}
        apiKey={config.GOOGLE_MAPS_API_KEY}
        language={config.DEFAULT_LOCALE}
        region={config.DEFAULT_REGION}
        version="weekly"
      >
        <Map
          mapKey={mapKey}
          minZoom={2}
          maxZoom={18}
          onIdle={onIdle}
          onClick={onClick}
          onInit={handleInit}
          fullscreenControl={false}
          streetViewControl={false}
          mapTypeControl={false}
          zoomControl={zoomControl ?? !disableMovement}
          clickableIcons={false}
          disableDoubleClickZoom={disableDoubleClickZoom ?? disableMovement}
          scrollwheel={!disableMovement}
          panControl={!disableMovement}
          mapId={mapId}
          backgroundColor={theme.palette.neutral01[100]}
          restriction={
            restrictBounds
              ? {
                  latLngBounds: MAP_BOUNDS,
                  strictBounds: true,
                }
              : undefined
          }
        >
          {children}
        </Map>
      </Wrapper>
    </MapStyled>
  );
};

export default MapWrapper;
