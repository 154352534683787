import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { PartnerParams } from 'types/episode.types';

const getPartner = async (params: PartnerParams) => {
  const { data } = await apiService.getPartner(params);

  return data;
};

export const usePartner = (episodeId = '') => {
  const { userId = '' } = useAuth();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.episode.partner(episodeId),
    queryFn: () => getPartner({ userId, episodeId }),
    enabled: !!userId && !!episodeId,
    retry: (_, error: AxiosError) => error.response?.status !== 404,
    meta: { persist: true },
  });

  return {
    data,
    isLoading,
  };
};
