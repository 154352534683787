import { memo, SVGProps } from 'react';

const SvgIcoShare = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M4.14296 11.5725C5.56312 11.5725 6.71439 10.4213 6.71439 9.00112C6.71439 7.58096 5.56312 6.42969 4.14296 6.42969C2.7228 6.42969 1.57153 7.58096 1.57153 9.00112C1.57153 10.4213 2.7228 11.5725 4.14296 11.5725Z"
      stroke="#EBD457"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8573 16.43C15.2775 16.43 16.4287 15.2787 16.4287 13.8585C16.4287 12.4384 15.2775 11.2871 13.8573 11.2871C12.4372 11.2871 11.2859 12.4384 11.2859 13.8585C11.2859 15.2787 12.4372 16.43 13.8573 16.43Z"
      stroke="#EBD457"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8573 6.71512C15.2775 6.71512 16.4287 5.56385 16.4287 4.14369C16.4287 2.72353 15.2775 1.57227 13.8573 1.57227C12.4372 1.57227 11.2859 2.72353 11.2859 4.14369C11.2859 5.56385 12.4372 6.71512 13.8573 6.71512Z"
      stroke="#EBD457"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44019 7.85854L11.5602 5.28711"
      stroke="#EBD457"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44019 10.1445L11.5602 12.716"
      stroke="#EBD457"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(SvgIcoShare);
export default Memo;
