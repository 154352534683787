/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */

import { isDev, isStag } from 'utils/env.utils';

import heatmapEditor from './editor/heatmap';
import heatmapLiveEditor from './editor/heatmap/A45Dg56ETvp90';
import guessGame from './guess-game';
import home from './home';
import { Route } from './routes.types';

const routes = [home, guessGame];

if (isStag() || isDev()) {
  routes.push(heatmapEditor);
  routes.push(heatmapLiveEditor);
}

export default routes as Array<Route>;
