import { memo, SVGProps } from 'react';

const SvgIcoCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect
      x={1}
      y={1}
      width={18}
      height={18}
      fill="#EBD457"
      stroke="black"
      strokeWidth={2}
    />
    <path
      d="M4.85742 10.5715L8.28599 14.0001L15.7146 6.57153"
      stroke="black"
      strokeWidth={3.46253}
    />
  </svg>
);
const Memo = memo(SvgIcoCheck);
export default Memo;
