import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    neutral01: {
      100: '#000000',
      200: '#0D0D0D',
      300: '#3D3D3D',
      400: '#8A8A8A',
      500: '#A6A6A6',
      600: '#121212',
    },
    neutral02: {
      100: '#FFFFFF',
    },
    brand01: {
      100: '#EBD457',
    },
    cta01: {
      100: '#EBD457',
    },
    primary: {
      main: '#EBD457',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000',
    },
    error: {
      main: '#F21600',
    },
    success: {
      main: '#4DF200',
    },
    gradientBackgrounds: {
      yellow:
        'radial-gradient(92.01% 90.42% at 49.95% 50%, rgba(235, 212, 87, 0) 0%, rgba(235, 212, 87, 0.234) 100%), black',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
    h1: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '47px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '38px',
      textTransform: 'uppercase',
    },
    h2Secondary: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '44px',
    },
    h3: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '29px',
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    h4Secondary: {
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '27px',
    },
    h5: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase',
    },
    h5Secondary: {
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '22px',
    },
    h6: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    h6Secondary: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
    },
    h7: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
    },
    paragraph1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '150%',
    },
    paragraph2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '150%',
    },
    caption1: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '14px',
    },
    cta1: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase',
    },
    cta2: {
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    textLink1: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      textDecorationLine: 'underline',
    },
  },
});
