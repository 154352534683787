import { memo, SVGProps } from 'react';

const SvgIcoCross = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect x={2} y={2} width={16} height={16} fill="#3D3D3D" />
    <path
      d="M4.85742 10.5715L8.28599 14.0001L15.7146 6.57153"
      stroke="black"
      strokeWidth={3.46253}
    />
    <rect
      x={1}
      y={1}
      width={18}
      height={18}
      fill="white"
      stroke="black"
      strokeWidth={2}
    />
    <path
      d="M6.03906 6.03931L13.9606 13.9609"
      stroke="black"
      strokeWidth={2.5}
    />
    <path
      d="M13.9609 6.03931L6.03939 13.9609"
      stroke="black"
      strokeWidth={2.5}
    />
  </svg>
);
const Memo = memo(SvgIcoCross);
export default Memo;
