import React, { useCallback } from 'react';

import { Feature } from 'types/features.types';

import { FeatureFlags } from './FeatureFlagsProvider';

export const useFeatureFlags = () => {
  const { features } = React.useContext(FeatureFlags);

  const hasFeature = useCallback(
    (flag: Feature) => {
      return features?.includes(flag);
    },
    [features],
  );

  return { features, hasFeature };
};
