import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Link,
  Typography,
} from '@mui/material';
import { motion } from 'motion/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Participant } from 'types/episode.types';

import { IcoCheckOverflow, IcoCorner } from 'components/@icons';

interface Props {
  participant: Participant;
  size?: number;
  selected?: boolean;
  onClick?(participant: Participant): void;
}

const ParticipantAvatar: React.FC<Props> = ({
  participant,
  size = 96,
  selected,
  onClick,
}) => {
  const handleClick = () => {
    if (!participant.lostInEpisode && onClick) {
      onClick(participant);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <CardActionArea
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={handleClick}
      >
        <Box position="relative">
          <Box position="absolute" top={0} left={2}>
            <IcoCorner width={11} />
          </Box>
          <Box position="absolute" bottom={-8} right={2}>
            <IcoCorner style={{ transform: 'scale(-1)' }} width={11} />
          </Box>
          {selected && (
            <Box zIndex={1} position="absolute" right={-4} bottom={-10}>
              <IcoCheckOverflow width={32} height={32} />
            </Box>
          )}
          <Avatar
            component={motion.div}
            initial="default"
            animate="default"
            variants={{
              default: {
                borderWidth: selected ? 8 : 0,
              },
              selected: {
                borderWidth: 8,
              },
            }}
            sx={{
              width: size,
              height: size,
              color: 'primary.main',
              border: selected ? 8 : 0,
            }}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
            imgProps={{
              sx: {
                filter: participant.lostInEpisode
                  ? 'grayscale(100%)'
                  : undefined,
              },
            }}
            src={participant.imageUrl}
          />
        </Box>
        <Typography variant="h5Secondary" mt={2}>
          {participant.firstName}
        </Typography>
        {onClick && !participant.lostInEpisode && (
          <Link component="span">
            <FormattedMessage id="label.select" />
          </Link>
        )}
        {participant.lostInEpisode && (
          <Typography variant="paragraph2" fontSize={12} color="neutral01.500">
            <FormattedMessage id="participant.lost_in_episode.label" />
          </Typography>
        )}
      </CardActionArea>
    </Card>
  );
};

export default ParticipantAvatar;
