import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { motion, Transition, Variants } from 'motion/react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAnalytics } from 'hooks';
import { useEpisodes, useMiniGames } from 'queries';
import { navigationActions } from 'store/navigation/navigation.slice';
import { EventName } from 'types/analytics.types';
import { Games } from 'types/game.types';

import { Routes } from 'pages/routes.constants';

import { GameCard } from 'components/@games';
import { IcoBinocular, IcoQuiz } from 'components/@icons';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const GamesContent = () => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const { nextEpisode } = useEpisodes();
  const { data } = useMiniGames(nextEpisode?.id);

  const isFinished = (game: Games) => {
    if (data) {
      if (game === Games.Quiz) {
        return data.quiz.every((q) => q.userAnswer);
      } else {
        return data.guessGame.every((q) => q.userAnswer);
      }
    }
  };

  const handleStartGame = (game: Games) => {
    if (game === Games.Quiz) {
      trackEvent({ eventName: EventName.Quiz });
      dispatch(
        navigationActions.MODAL_OPEN({
          key: 'quiz',
        }),
      );
    } else {
      trackEvent({ eventName: EventName.Guess });
      navigate(Routes.GuessGame);
      dispatch(navigationActions.MODAL_CLOSE());
    }
  };

  const getIcon = (game: Games) => {
    if (game === Games.Quiz) {
      return <IcoQuiz width={isSmall ? 79 : 158} height={isSmall ? 52 : 102} />;
    } else {
      return (
        <IcoBinocular width={isSmall ? 44 : 86} height={isSmall ? 44 : 86} />
      );
    }
  };

  const showQuiz = !!data?.quiz.length;
  const showGuessGame = !!data?.guessGame.length;

  return (
    <Box pb={6}>
      <motion.div initial="initial" animate="animate">
        <motion.div variants={animationVariant} transition={transition(0)}>
          <Typography variant="h2">
            <FormattedMessage id="games.modal.title" />
          </Typography>
        </motion.div>
        <motion.div variants={animationVariant} transition={transition(1)}>
          <Typography variant="h4Secondary" component="p" mt={{ xs: 2, md: 5 }}>
            <FormattedMessage id="games.modal.description" />
          </Typography>
        </motion.div>
        <motion.div variants={animationVariant} transition={transition(2)}>
          <Grid
            mt={{ xs: 3, md: 6 }}
            container
            columnSpacing={6}
            gap={{ xs: 2, md: 0 }}
          >
            {Object.values(Games).map((game) => {
              if (game === Games.Quiz && !showQuiz) return null;
              if (game === Games.Guesser && !showGuessGame) return null;

              return (
                <Grid key={game} item xs={12} md={6}>
                  <GameCard
                    icon={getIcon(game)}
                    title={`games.modal.${game}.title`}
                    subtitle={`games.modal.${game}.subtitle`}
                    finished={isFinished(game)}
                    button={{
                      label: `games.modal.${game}.button`,
                      onClick: () => handleStartGame(game),
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default GamesContent;
