import { Box, Link, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { IcoVtmGo } from 'components/@icons/branded';

const ButtonStyled = styled(Link)(({ theme }) => ({
  borderRadius: 47,
  padding: theme.spacing(0, 3),
  paddingTop: 2,
  backgroundColor: 'rgb(255, 0, 255)',
  color: '#fff',
  height: 38,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '5px',
  textTransform: 'none',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: 'auto',
  '&:hover': {
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: 'rgb(235, 0, 235)',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 0.3125rem 0.625rem 0px',
  },
}));

interface Props {
  onClick?: () => void;
  href: string;
  rewatch?: boolean;
}

const VTMGoButton = ({ onClick, href, rewatch }: Props) => {
  return (
    <ButtonStyled
      href={href}
      onClick={onClick}
      target="_blank"
      underline="none"
    >
      <FormattedMessage
        id={rewatch ? 'general.rewatch.label' : 'general.watch.label'}
      />

      <Box mt="2px">
        <IcoVtmGo width={60} height={15} />
      </Box>
    </ButtonStyled>
  );
};

export default VTMGoButton;
