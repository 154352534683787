import { memo, SVGProps } from 'react';

const SvgIcoPoiArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 11"
    fill="currentFill"
    {...props}
  >
    <path
      d="M7.12623 0.5H1.20711L11 10.2929L20.7929 0.5H14.8738L11.3536 4.02022L11 4.37377L10.6464 4.02022L7.12623 0.5Z"
      stroke="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoPoiArrow);
export default Memo;
