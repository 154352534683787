import { memo, SVGProps } from 'react';

const SvgIcoPoiBus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 58 58" fill="none" {...props}>
    <path
      d="M58 18.6895V25.5622L54.5636 28.9985L58 32.4349V39.3076L47.6909 28.9985L58 18.6895Z"
      fill="currentColor"
    />
    <path
      d="M0.000483513 39.3076V32.4349L3.43685 28.9985L0.000483513 25.5622V18.6895L10.3096 28.9985L0.000483513 39.3076Z"
      fill="currentColor"
    />
    <path
      d="M18.6914 0H25.5641L29.0005 3.43636L32.4368 0H39.3096L29.0005 10.3091L18.6914 0Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoPoiBus);
export default Memo;
