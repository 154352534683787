import { ButtonBase, styled } from '@mui/material';

export const Card = styled(ButtonBase)(({ theme }) => ({
  width: 147,
  height: 191,
  display: 'flex',
  flexShrink: 0,
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.neutral01[100],
  border: `1px solid ${theme.palette.neutral01[300]}`,
  padding: theme.spacing(1.5),
  transition: 'border 350ms',
  textAlign: 'start',
  '&:hover,:focus-visible': {
    border: `1px solid ${theme.palette.neutral02[100]}`,
    '@media (hover: none)': {
      border: `1px solid ${theme.palette.neutral01[300]}`,
    },
  },
}));
