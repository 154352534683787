import { memo, SVGProps } from 'react';

const SvgIcoCheckboxCorrect = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <rect width={20} height={20} fill="#4DF200" />
    <path d="M3 9.68L7.54054 14L17 5" stroke="black" strokeWidth={2} />
  </svg>
);
const Memo = memo(SvgIcoCheckboxCorrect);
export default Memo;
