import { memo, SVGProps } from 'react';

const SvgIcoDistance = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" {...props}>
    <g opacity={0.5} clipPath="url(#clip0_334_5693)">
      <path
        d="M7.69231 9.79158H9.31032V12.0648H7.69231V9.79158Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
      <path
        d="M7.69231 14.649H9.31032V16.9222H7.69231V14.649Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
      <path
        d="M7.69231 4.93514H9.31032V7.20833H7.69231V4.93514Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
      <path
        d="M7.69231 0.0777143H9.31032V2.35091H7.69231V0.0777143Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
      <path
        d="M16.9105 16.9223H15.2765L13.0537 0.0777143H14.685L16.9105 16.9223Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
      <path
        d="M0.0886574 16.9223L2.31416 0.0777143H3.94546L1.72265 16.9223H0.0886574Z"
        fill="white"
        stroke="white"
        strokeWidth={0.155429}
      />
    </g>
    <defs>
      <clipPath id="clip0_334_5693">
        <rect width={17} height={17} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoDistance);
export default Memo;
