import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import qs from 'query-string';

import { requestInterceptors, responseInterceptors } from './interceptors';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: (params) => {
    return qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'comma' });
  },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data);
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  // Request interceptors
  requestInterceptors.forEach((interceptor) => {
    manager.interceptors.request.use((config) => {
      return config;
    }, interceptor.error);
    manager.interceptors.request.use(interceptor.success, interceptor.error);
  });

  // Response interceptor
  responseInterceptors.forEach((interceptor) => {
    manager.interceptors.response.use(interceptor.success, interceptor.error);
  });

  return manager;
};

export { createManager };
