import React from 'react';

import config from 'config/env';

import { Feature } from 'types/features.types';

import { useFeatureFlags } from './useFeatureFlags';

export const FeatureFlags = React.createContext<{ features?: Array<Feature> }>(
  {},
);

interface FeatureFlagProps {
  children?: React.ReactNode;
  features: Array<Feature>;
}

export const FeatureFlag = ({
  features: requiredFeatures,
  children,
}: FeatureFlagProps) => {
  const { features } = useFeatureFlags();

  if (
    !requiredFeatures.length ||
    requiredFeatures.every((f) => features?.includes(f))
  ) {
    return <>{children}</>;
  }

  return null;
};

interface Props {
  children?: React.ReactNode;
}

export const FeatureFlagsProvider = ({ children }: Props) => {
  return (
    <FeatureFlags.Provider value={{ features: config.FEATURES }}>
      {children}
    </FeatureFlags.Provider>
  );
};
