import { memo, SVGProps } from 'react';

const SvgIcoX = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 49 36" fill="none" {...props}>
    <path
      d="M16.2574 0H31.9474L24.1024 9.8848L16.2574 0Z"
      fill="currentColor"
    />
    <path
      d="M31.9474 36H16.2574L24.1024 26.1118L31.9474 36Z"
      fill="currentColor"
    />
    <path
      d="M0 6.21051L9.2803 18.0169L0 29.8199H8.54745L17.8278 18.0169L8.54745 6.21051H0Z"
      fill="currentColor"
    />
    <path
      d="M48.2049 29.8199L38.9246 18.0135L48.2049 6.21051H39.6574L30.3771 18.0135L39.6574 29.8199H48.2049Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoX);
export default Memo;
