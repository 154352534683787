import { buttonClasses, Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: { variant: 'contained', color: 'primary', size: 'large' },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontFamily: 'Eurostile Extended',
      fontWeight: 700,
      borderRadius: 0,
      ...(ownerState.variant === 'icon' && {
        fontFamily: 'Nunito Sans',
        textTransform: 'uppercase',
        fontWeight: 600,
        minWidth: 40,
        display: 'flex !important',
        padding: theme.spacing(0, 1.2),
        backgroundColor:
          ownerState.color === 'primary'
            ? theme.palette.brand01[100]
            : theme.palette.neutral01[100],

        ...(ownerState.color === 'secondary' && {
          border: `1px solid ${theme.palette.neutral01[300]}`,
        }),

        color: theme.palette.neutral02[100],
        boxShadow:
          ownerState.color === 'primary'
            ? '0px 0px 12px rgba(235, 212, 87, 0.5)'
            : '0px 4px 4px rgba(0, 0, 0, 0.25)',

        '&:hover,:focus-visible': {
          backgroundColor:
            ownerState.color === 'primary'
              ? theme.palette.brand01[100]
              : theme.palette.neutral01[100],
          color: theme.palette.brand01[100],
          border:
            ownerState.color === 'primary'
              ? 'none'
              : `1px solid ${theme.palette.brand01[100]}`,
          boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',

          '@media (hover: none)': {
            backgroundColor:
              ownerState.color === 'primary'
                ? theme.palette.brand01[100]
                : theme.palette.neutral01[100],
            border:
              ownerState.color === 'primary'
                ? 'none'
                : `1px solid ${theme.palette.neutral01[300]}`,
            color: theme.palette.neutral02[100],
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          },
        },
        [`&.${buttonClasses.disabled}`]: {
          color: theme.palette.neutral01[300],
          border: `1px solid ${theme.palette.neutral01[300]}`,
        },
      }),
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.brand01[100],
      fontSize: '14px',
      color: theme.palette.neutral01[100],

      '&:hover,:focus-visible': {
        backgroundColor: theme.palette.brand01[100],
        boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.neutral01[300],
        backgroundColor: theme.palette.neutral01[400],
        border: 'unset',
      },
    }),
    textPrimary: ({ theme }) => ({
      fontSize: '10px',
      color: theme.palette.brand01[100],
      '&:hover,:focus-visible': {
        filter: 'brightness(1.05)',
        textShadow: '0px 0px 12px rgba(235, 212, 87, 1)',
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.brand01[100]}`,
      fontSize: '14px',
      color: theme.palette.brand01[100],

      '&:hover,:focus-visible': {
        border: `1px solid ${theme.palette.brand01[100]}`,
        boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.neutral01[300],
        border: `1px solid ${theme.palette.neutral01[300]}`,
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral01[200],
      border: `1px solid ${theme.palette.neutral01[300]}`,
      fontSize: '14px',
      color: theme.palette.neutral02[100],
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

      '&:hover,:focus-visible': {
        color: theme.palette.brand01[100],
        backgroundColor: theme.palette.neutral01[200],
        border: `1px solid ${theme.palette.brand01[100]}`,
        boxShadow: '0px 0px 12px rgba(235, 212, 87, 0.5)',
      },
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette.neutral01[300],
        border: `1px solid ${theme.palette.neutral01[300]}`,
      },
    }),
    sizeSmall: ({ theme }) => ({
      fontSize: 10,
      lineHeight: 1,
      padding: theme.spacing(1, 1.2),
    }),
    sizeMedium: ({ theme }) => ({
      fontSize: 10,
      lineHeight: 1,
      padding: theme.spacing(1, 1.2),
    }),
    sizeLarge: () => ({
      fontSize: 14,
      lineHeight: 1,
      height: 40,
    }),
  },
};
