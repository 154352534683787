import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useAuth } from 'hooks';
import { CacheTime, StaleTime } from 'queries/constants';
import { useEpisodes } from 'queries/episodes';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';

const getScoreBreakdown = async (userId: string, reveal?: string) => {
  const { data } = await apiService.getScoreBreakdown(userId, reveal);

  return data;
};

export const useScoreBreakDown = () => {
  const { userId = '' } = useAuth();
  const { currentEpisode } = useEpisodes();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.scoreBreakDown(),
    queryFn: () =>
      getScoreBreakdown(
        userId,
        // We pass the episode id when it's revealed. So we know 100% sure the call isn't cached
        currentEpisode?.isRevealed ? currentEpisode.id : undefined,
      ),
    enabled: !!userId.length,
    staleTime: StaleTime.TEN_SECONDS,
    gcTime: CacheTime.FOREVER,
    meta: { persist: true },
  });

  const totalScoreForEpisode = useCallback(
    (episodeId: string) => {
      const breakdown = data?.breakdown.find((d) => d.episodeId === episodeId);
      if (breakdown) {
        let score = 0;
        score += breakdown.userPartner?.pointsScored ?? 0;
        score += breakdown.userPin?.pointsScored ?? 0;
        return score;
      }
    },
    [data],
  );

  const breakdownForEpisode = useCallback(
    (episodeId: string) => {
      return data?.breakdown.find((d) => d.episodeId === episodeId);
    },
    [data],
  );

  return {
    data,
    isLoading,
    totalScore: data?.totalScore ?? 0,
    totalScoreForEpisode,
    breakdownForEpisode,
  };
};
