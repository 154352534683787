import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Keys, QueryKeys } from 'queries/QueryKeys';
import { authActions } from 'store/auth/auth.slice';

export const useLogout = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const cleanup = useCallback(() => {
    const filters: QueryFilters = {
      queryKey: [Keys.User],
      predicate: (query) => {
        // We only clear the cache if the queryKey is not the current user
        if (_isEqual(query.queryKey, QueryKeys.user.me())) {
          return false;
        }
        return true;
      },
    };
    queryClient.cancelQueries(filters);
    queryClient.removeQueries(filters);
  }, [queryClient]);

  const logout = useCallback(() => {
    dispatch(authActions.LOGOUT());
    cleanup();
  }, [cleanup, dispatch]);

  return {
    logout,
  };
};
