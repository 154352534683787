import { LeaderboardUser } from 'types/leaderboard.types';

import RestApiService from './';

export default {
  getLeaderboard(
    this: RestApiService,
    previousEpisode = false,
    reveal?: string,
  ) {
    return this.api.get<Array<LeaderboardUser>>('/leaderboard', {
      params: { previousEpisode: previousEpisode ? 1 : 0, reveal },
    });
  },
  getUserLeaderboard(
    this: RestApiService,
    userId: string,
    previousEpisode = false,
    reveal?: string,
  ) {
    return this.api.get<Array<LeaderboardUser>>(
      `/users/${userId}/leaderboard`,
      {
        params: { previousEpisode: previousEpisode ? 1 : 0, reveal },
        authEnabled: true,
      },
    );
  },
};
