import config from 'config/env';

import {
  ApiEpisode,
  Participant,
  PartnerParams,
  SelectPartnerParams,
} from 'types/episode.types';
import { Coordinate } from 'types/game.types';

import RestApiService from './';

export default {
  getEpisodes(this: RestApiService) {
    return this.api.get<Array<ApiEpisode>>(`/episodes`);
  },
  getPartner(this: RestApiService, { userId, episodeId }: PartnerParams) {
    return this.api.get<Participant>(
      `/users/${userId}/episodes/${episodeId}/partner`,
      { authEnabled: true },
    );
  },
  selectPartner(
    this: RestApiService,
    { userId, episodeId, participant }: SelectPartnerParams,
  ) {
    return this.api.post<Participant>(
      `/users/${userId}/episodes/${episodeId}/partner`,
      {
        participantId: participant.id,
      },
      { authEnabled: true },
    );
  },
  // Production only
  getProductionEpisodes(this: RestApiService) {
    return this.api.get<Array<ApiEpisode>>(
      `${config.PRODUCTION_API_BASE}/episodes`,
    );
  },
  getHeatmap(this: RestApiService, episodeId: string) {
    // Force production api
    return this.api.get<Array<Coordinate>>(
      `${config.PRODUCTION_API_BASE}/episodes/${episodeId}/heatmap`,
    );
  },
};
