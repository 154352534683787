// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';
import '@formatjs/intl-relativetimeformat/locale-data/da';

import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES from './translations/translations.json';

const { DEFAULT_LOCALE: DEFAULT_CONFIG_LOCALE } = config;

const locales = [DEFAULT_CONFIG_LOCALE];

const intlCache = createIntlCache();

const removeNullValues = (messages: { [key: string]: string | null }) =>
  JSON.parse(
    JSON.stringify(messages, (_, value) => (value === null ? '' : value)),
  );

const createIntls = (): { [key: string]: IntlShape } => {
  const intls: { [key: string]: IntlShape } = {
    [DEFAULT_CONFIG_LOCALE]: createIntl(
      {
        locale: DEFAULT_CONFIG_LOCALE,
        messages: removeNullValues(MESSAGES),
      },
      intlCache,
    ),
  };

  return intls;
};

const intl = createIntls();

const DEFAULT_LOCALE = DEFAULT_CONFIG_LOCALE;

export { DEFAULT_LOCALE, intl, locales };
