import { Feature } from 'types/features.types';

import jsonFile from './config.json';

const jsonConfig = jsonFile as {
  brand: 'dr' | 'vtm';
  program: 'Bestemming X' | 'Destination X';
  external: {
    program_url: string;
    watch_now_url: string;
    profile_page_url: string;
  };
  locale: string;
  locale_date: string;
  region: string;
  api: {
    production_base: string;
    base: string;
  };
  auth: {
    client_id: string;
    base: string;
    redirect_uri: string;
    logout_redirect_uri?: string;
    type: 'cookie' | 'token';
  };
  google_maps: {
    ids: {
      dark: string;
      heatmap: string;
    };
    center: {
      lat: number;
      lng: number;
    };
  };
  config: {
    tag_manager_id?: string;
    login_url: string;
    client_url: string;
    iframe_client_url: string;
    first_name_url?: string;
  };
  analytics?: {
    tag_manager_id: string;
    events: {
      label: string;
      category: string;
      event: string;
      action_prefix: string;
    };
  };
  features?: Array<Feature>;
};

export interface ENV_CONFIG {
  NAME: string;
  BRAND: (typeof jsonConfig)['brand'];
  PROGRAM: (typeof jsonConfig)['program'];
  PROGRAM_URL: (typeof jsonConfig)['external']['program_url'];
  WATCH_NOW_URL: (typeof jsonConfig)['external']['watch_now_url'];
  EXTERNAL_PROFILE_PAGE_URL: (typeof jsonConfig)['external']['profile_page_url'];
  VERSION: string;
  BUILD_ENV: string;
  API_BASE: string;
  PRODUCTION_API_BASE: string;
  AUTH_API_BASE: string;
  AUTH_REDIRECT_URI: string;
  AUTH_LOGOUT_REDIRECT_URI?: string;
  AUTH_TYPE: (typeof jsonConfig)['auth']['type'];
  CLIENT_URL: string;
  IFRAME_CLIENT_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  DEFAULT_REGION: string;
  AUTH_CLIENT_ID: string;
  GOOGLE_MAPS_API_KEY: string;
  GOOGLE_MAPS_IDS: {
    dark: string;
    heatmap: string;
  };
  MAPS_INITIAL_CENTER: {
    lat: number;
    lng: number;
  };
  ANALYTICS: (typeof jsonConfig)['analytics'];
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
  FEATURES: Array<Feature>;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  BUILD_ENV: process.env.REACT_APP_BUILD_ENV || '',
  PROGRAM: jsonConfig.program || 'Destination X',
  PROGRAM_URL: jsonConfig.external.program_url || '',
  WATCH_NOW_URL: jsonConfig.external.watch_now_url || '',
  EXTERNAL_PROFILE_PAGE_URL: jsonConfig.external.profile_page_url || '',

  // api
  API_BASE: jsonConfig.api.base || '',
  PRODUCTION_API_BASE: jsonConfig.api.production_base || '',
  CLIENT_URL: jsonConfig.config.client_url || '',
  IFRAME_CLIENT_URL: jsonConfig.config.iframe_client_url || '',

  FEATURES: jsonConfig.features ?? [],

  AUTH_TYPE: jsonConfig.auth.type,

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn: 'https://4b46cd28e52045de99d8cacecad5d255@o190235.ingest.sentry.io/4504248382652416',
    environment: `${jsonConfig.brand}-${process.env.REACT_APP_BUILD_ENV}`,
    release: `${jsonConfig.brand}-${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // auth
  AUTH_CLIENT_ID: jsonConfig.auth.client_id,
  AUTH_API_BASE: jsonConfig.auth.base,
  AUTH_REDIRECT_URI: jsonConfig.auth.redirect_uri,
  AUTH_LOGOUT_REDIRECT_URI: jsonConfig.auth.logout_redirect_uri,

  // analytics
  ANALYTICS: jsonConfig.analytics,

  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  GOOGLE_MAPS_IDS: jsonConfig.google_maps.ids,

  MAPS_INITIAL_CENTER: jsonConfig.google_maps.center,

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: jsonConfig.locale ?? 'nl-BE',
  DEFAULT_LOCALE_DATE: jsonConfig.locale_date ?? 'nl',
  DEFAULT_REGION: jsonConfig.region ?? 'BE',
  BRAND: jsonConfig.brand,
};

export default config;
