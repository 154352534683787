import { memo, SVGProps } from 'react';

const SvgIcoArrowSingle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 14" fill="none" {...props}>
    <path
      d="M-5.82818e-07 0.333659L-3.88546e-07 4.7781L2.66667 7.00033L-1.94273e-07 9.22255L0 13.667L8 7.00033L-5.82818e-07 0.333659Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIcoArrowSingle);
export default Memo;
