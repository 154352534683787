import { Box, ButtonBase, Stack, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import adImage from 'assets/branded/jpg/img-brussels-airlines-sponsor-bg.jpg';
import logoImage from 'assets/branded/png/ico-sponsor-brussel-airlines.png';

import { useAnalytics } from 'hooks';
import { EventName } from 'types/analytics.types';

const BackgroundImage = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    height: '50%',
    zIndex: -1,
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 61.65%), linear-gradient(180deg, #000000 0%,  rgba(0, 0, 0, 0) 22%), url(${adImage})`,
    [theme.breakpoints.up('md')]: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundPosition: 'center top',
      backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 61.65%), url(${adImage})`,
    },
  };
});

const VTMSecondarySponsor = () => {
  const { trackEvent } = useAnalytics();

  return (
    <ButtonBase
      href="https://www.brusselsairlines.com/be/nl"
      target="_blank"
      onClick={() => trackEvent({ eventName: EventName.PartnerOne })}
    >
      <Stack
        py={{ xs: 3, sm: 6 }}
        pl={{ xs: 3, sm: 6 }}
        pr={{ xs: 3, sm: 3 }}
        gap={4}
        position="relative"
      >
        <BackgroundImage />
        <Stack
          direction={{ xs: 'column', sm: 'row-reverse' }}
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex={2}
        >
          {/*<Box
          position="relative"
          minWidth={{ xs: '100%', sm: 'auto' }}
          display="flex"
          justifyContent="center"
          mb={{ xs: 6, sm: 0 }}
        >
           <Stack position="absolute" right={0} bottom={-32}>
            <Box
              bgcolor="neutral01.100"
              position="absolute"
              right={0}
              bottom={0}
              left={0}
              top={0}
              zIndex={-1}
            />
            <Stack display={{ md: 'none' }} alignItems="flex-end">
              <Typography variant="paragraph2">
                <FormattedMessage id="home.ad.travel.link.info" />
              </Typography>
              <Link
                lineHeight="14px"
                variant="h5"
                target="_blank"
                href="https://www.brusselsairlines.com/be/nl/book-a-flight/our-promotions?region=EU&from=BRU&tripType=ONEWAY&utm_source=dpg&utm_medium=referral"
                onClick={() =>
                  trackEvent({ eventName: EventName.PartnerOne })
                }
              >
                brusselsairlines.com
              </Link>
            </Stack>
          </Stack>
        </Box>*/}
          <Stack gap={2}>
            <Typography
              variant="h4"
              component="h3"
              sx={{ whiteSpace: 'pre-line' }}
            >
              <FormattedMessage
                id="home.ad.travel.title"
                values={{
                  b: (value) => (
                    <Typography
                      variant="h4"
                      component="span"
                      color="brand01.100"
                    >
                      {value}
                    </Typography>
                  ),
                }}
              />
            </Typography>
            <Typography variant="caption1" maxWidth={{ xs: '90%', md: '60%' }}>
              <FormattedMessage id="home.ad.travel.info" />
            </Typography>
          </Stack>
        </Stack>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <img src={logoImage} alt="brussels airlines logo" />

          {/* <Stack display={{ xs: 'none', md: 'flex' }} alignItems="flex-end">
          <Typography variant="paragraph2">
            <FormattedMessage id="home.ad.travel.link.info" />
          </Typography>
          <Link
            lineHeight="14px"
            variant="h5"
            target="_blank"
            href="https://www.brusselsairlines.com/be/nl/book-a-flight/our-promotions?region=EU&from=BRU&tripType=ONEWAY&utm_source=dpg&utm_medium=referral"
            onClick={() =>
              trackEvent({ eventName: EventName.PartnerOne })
            }
          >
            brusselsairlines.com
          </Link>
        </Stack> */}
        </Box>
      </Stack>
    </ButtonBase>
  );
};

export default VTMSecondarySponsor;
