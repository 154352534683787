import { memo, SVGProps } from 'react';

const SvgIcoGeoguessPin = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 24" fill="none" {...props}>
    <path
      d="M11.623 18.318H9.74867H8.81148H7.87429H5.99991L8.81148 21.0625L11.623 18.318Z"
      fill="black"
    />
    <rect
      x={1.01162}
      y={1.76162}
      width={15.9768}
      height={15.5471}
      stroke="black"
      strokeWidth={2.02325}
    />
    <path
      d="M7.5812 13V11.127H9.58513V13H7.5812ZM9.87716 8.79074C10.1121 8.75717 10.3035 8.69675 10.4512 8.60948C10.5988 8.51549 10.6727 8.36109 10.6727 8.14626C10.6727 7.93143 10.5821 7.77367 10.4008 7.67297C10.2195 7.57227 10.0081 7.52192 9.76639 7.52192L8.93058 7.50178L8.21561 7.52192C7.91351 7.52192 7.68526 7.57898 7.53085 7.69311C7.38316 7.80724 7.30931 8.00528 7.30931 8.28724C7.30931 8.44165 7.31602 8.54235 7.32945 8.58934H5.5672C5.5672 7.73003 5.69811 7.12919 5.95993 6.78681C6.22175 6.44443 6.75546 6.27324 7.56106 6.27324L8.94065 6.22289C9.10848 6.22289 9.28639 6.22625 9.47436 6.23296C9.66233 6.23296 9.85702 6.23632 10.0584 6.24303C10.3135 6.24303 10.6559 6.2766 11.0856 6.34373C11.5152 6.40415 11.8375 6.58205 12.0523 6.87744C12.2671 7.17283 12.3745 7.5857 12.3745 8.11605V8.2671C12.3745 8.57591 12.3544 8.83102 12.3141 9.03242C12.2872 9.21368 12.1899 9.3983 12.0221 9.58627C11.8542 9.77424 11.3877 9.93872 10.6223 10.0797C9.85702 10.2207 9.46429 10.3684 9.44415 10.5228L9.45422 10.6335H7.72218C7.72218 9.84809 7.84638 9.38487 8.09477 9.24389C8.34988 9.0962 8.94401 8.94515 9.87716 8.79074Z"
      fill="black"
    />
  </svg>
);
const Memo = memo(SvgIcoGeoguessPin);
export default Memo;
