import { AxiosInstance } from 'axios';

import authApi from './auth.api';
import episodeApi from './episodes.api';
import goldenXApi from './golden-x.api';
import leaderboardApi from './leaderboard.api';
import miniGamesApi from './mini-games.api';
import participantsApi from './participants.api';
import settingsApi from './settings.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // AUTH
  public getAuth = authApi.getAuth;

  // EPISODES
  public getEpisodes = episodeApi.getEpisodes;
  public getProductionEpisodes = episodeApi.getProductionEpisodes;
  public getPartner = episodeApi.getPartner;
  public selectPartner = episodeApi.selectPartner;
  public getHeatmap = episodeApi.getHeatmap;

  // USER
  public getUser = userApi.getUser;
  public getUserPin = userApi.getUserPin;
  public postUserPin = userApi.postUserPin;
  public getScoreBreakdown = userApi.getScoreBreakdown;

  // MINI GAMES
  public getMiniGames = miniGamesApi.getMiniGames;
  public postGuessGameAnswer = miniGamesApi.postGuessGameAnswer;
  public postQuizAnswer = miniGamesApi.postQuizAnswer;

  // SETTINGS
  public getGameRules = settingsApi.getGameRules;

  // PARTICIPANTS
  public getParticipants = participantsApi.getParticipants;

  // LEADERBOARD
  public getLeaderboard = leaderboardApi.getLeaderboard;
  public getUserLeaderboard = leaderboardApi.getUserLeaderboard;

  // GOLDEN-X
  public getFinalGoldenX = goldenXApi.getFinalGoldenX;
  public postUserGoldenPin = userApi.postUserGoldenPin;
  public getGoldenXGuesses = userApi.getGoldenXGuesses;
  public getGoldenXTips = userApi.getGoldenXTips;
}

export default RestApiService;
