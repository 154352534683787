import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  PersistQueryClientProviderProps,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { AxiosError } from 'axios';

import config from 'config/env';

import { QueryKeys } from 'queries';

const queryCache = new QueryCache();

const localStoragePersister = createSyncStoragePersister({
  key: `bx-cache-queries-${config.BRAND}`,
  storage: window.localStorage,
  retry: removeOldestQuery,
});

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        const status = (error as AxiosError)?.response?.status;
        const code = (error as AxiosError)?.code;
        if (status === 401 || status === 404 || code === 'ECONNABORTED') {
          if (status === 401) {
            queryClient.setQueryData(QueryKeys.user.me(), false);
          }

          return false;
        }
        return failureCount < 2;
      },
    },
  },
});

const BUSTER_VERSION = '1';

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] =
  {
    buster: BUSTER_VERSION,
    persister: localStoragePersister,
    dehydrateOptions: {
      shouldDehydrateMutation: () => false,
      shouldDehydrateQuery: ({ meta, state }) => {
        return state.status === 'success' && !!meta?.persist;
      },
    },
    hydrateOptions: {
      defaultOptions: {
        queries: {
          meta: { persist: false },
        },
      },
    },
  };
