import { Box, styled } from '@mui/material';
import { BoxProps } from '@mui/system';
import ReactPlayer from 'react-player/lazy';

import { EMBEDDED_URLS } from './constants';
import { transformEmbedUrl } from './utils';

interface Props extends BoxProps {
  videoUrl: string;
  title?: string;
  autoPlay?: boolean;
}

const VideoFrame = styled('iframe')(() => ({
  border: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  overflow: 'hidden',
}));

const VideoWrapper = styled('div')(() => ({
  border: 0,
  position: 'absolute',
  left: 0,
  top: 0,
  overflow: 'hidden',
}));

const VideoPlayer = ({ videoUrl, title, autoPlay, ...rest }: Props) => {
  const isEmbed = Object.values(EMBEDDED_URLS).some((url) =>
    videoUrl.includes(url),
  );

  return (
    <Box {...rest}>
      <Box
        title={title}
        pt="56.25%"
        position="relative"
        height={0}
        width="100%"
        bgcolor="neutral01.200"
      >
        {!isEmbed ? (
          <VideoWrapper>
            <ReactPlayer url={videoUrl} width="100%" height="100%" />
          </VideoWrapper>
        ) : (
          <VideoFrame
            title={title}
            allowFullScreen
            height="100%"
            width="100%"
            src={transformEmbedUrl(videoUrl, { autoPlay })}
          />
        )}
      </Box>
    </Box>
  );
};
export default VideoPlayer;
