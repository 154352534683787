import { memo, SVGProps } from 'react';

const SvgIcoPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 22" fill="none" {...props}>
    <path
      d="M0.499999 14.8738L0.499999 20.7929L10.2929 11L0.5 1.20711L0.5 7.12623L4.02022 10.6464L4.37377 11L4.02022 11.3536L0.499999 14.8738Z"
      fill="black"
      stroke="white"
    />
  </svg>
);
const Memo = memo(SvgIcoPlay);
export default Memo;
