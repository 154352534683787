import { Stack, Typography } from '@mui/material';
import { motion, Variants } from 'motion/react';

import { VideoPlayer } from 'components/@common/VideoPlayer';

interface Props {
  url: string;
  title?: string;
  autoPlay?: boolean;
}

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.7,
    },
  },
};

const VideoContent = ({ title, url, autoPlay }: Props) => {
  return (
    <Stack
      component={motion.div}
      initial="initial"
      animate="animate"
      transition={{ staggerChildren: 0.4 }}
      flex={1}
      flexDirection="column"
      gap={4}
    >
      <Typography
        component={motion.h2}
        variants={animationVariant}
        variant="h3"
        mb={2}
      >
        {title}
      </Typography>
      <motion.div variants={animationVariant} style={{ flex: 1 }}>
        <VideoPlayer videoUrl={url} title={title} autoPlay={autoPlay} />
      </motion.div>
    </Stack>
  );
};

export default VideoContent;
