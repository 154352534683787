import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { isNil } from 'lodash';

import { GuessGameCoordinate, QuizQuestion } from 'types/game.types';

interface BlockProps {
  isCorrect?: boolean;
  children: string | number;
  size?: number;
}

const ProgressItem = ({ isCorrect, children, size = 18 }: BlockProps) => {
  const getStyles = (): SxProps<Theme> => {
    switch (isCorrect) {
      case false:
        return {
          bgcolor: 'neutral01.300',
        };
      case true:
        return {
          bgcolor: 'brand01.100',
        };
      default:
        return {
          bgcolor: 'neutral01.200',
          border: '1px solid',
          borderColor: 'neutral02.100',
        };
    }
  };

  return (
    <Box
      width={size}
      height={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={getStyles()}
    >
      <Typography
        variant="h6"
        component="span"
        color={!isNil(isCorrect) ? 'neutral01.100' : 'neutral02.100'}
      >
        {children}
      </Typography>
    </Box>
  );
};

interface Props {
  items: Array<(GuessGameCoordinate | QuizQuestion)['userAnswer']>;
  size?: number;
}

const GameProgress = ({ items, size }: Props) => {
  if (items.length <= 1) {
    return null;
  }

  return (
    <Stack direction="row" gap="3px">
      {items.map((item, idx) => (
        <ProgressItem key={`${idx}`} size={size} isCorrect={item?.isCorrect}>
          {idx + 1}
        </ProgressItem>
      ))}
    </Stack>
  );
};

export default GameProgress;
